<template>
<ul class="menu-nav">
  <LinkMenu
    nameRoute="dashboardAdmin"
    :title="$t('PAGE.DASHBOARD')"
    icon="/media/svg/icons/Layout/Layout-grid.svg"
    @click="onClick"
  />
  <LinkMenu
    nameRoute="company"
    :title="$t('PAGE.COMPANIES')"
    icon="/media/svg/icons/Clothes/Shirt.svg"
    @click="onClick"
  />
  <LinkMenu
    nameRoute="companyUser"
    :title="$t('PAGE.COMPANY_USERS')"
    icon="/media/svg/icons/Communication/Group.svg"
    @click="onClick"
  />
</ul>
</template>

<script>
import LinkMenu from '../LinkMenu'

export default {
  name: 'KTMenu',
  components: { LinkMenu },
  methods: {
    onClick() {
      const el = document.querySelector('.aside-overlay')
      if (el) el.click()
    },
    hasActiveChildren(match) {
      return this.$route.path.indexOf(match) !== -1
    },
  },
}
</script>
