<template>
<div class="d-flex flex-column flex-root">
  <!-- begin:: Header Mobile -->
  <KTHeaderMobile />
  <!-- end:: Header Mobile -->

  <Loader v-if="loaderEnabled" :logo="loaderLogo" />

  <!-- begin::Body -->
  <div class="d-flex flex-row flex-column-fluid page">
    <!-- begin:: Aside Left -->
    <KTAside v-if="asideEnabled" />
    <!-- end:: Aside Left -->

    <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
      <!-- begin:: Header -->
      <KTHeader />
      <!-- end:: Header -->

      <!-- begin:: Content -->
      <div
        id="kt_content"
        class="content d-flex flex-column flex-column-fluid"
      >
        <!-- begin:: Content Body -->
        <div class="d-flex flex-column-fluid">
          <div
            :class="{
              'container-fluid': contentFluid,
              container: !contentFluid
            }"
            class="px-0 px-md-5"
          >
            <transition name="fade-in-up">
              <ErrorBoundary>
                <router-view />
              </ErrorBoundary>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--<KTStickyToolbar v-if="toolbarDisplay"></KTStickyToolbar>-->
  <KTScrollTop />
  <modals-container />
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import KTAside from '@/view/layout/aside/admin/Aside.vue'
import KTHeader from '@/view/layout/header/Header.vue'
import KTHeaderMobile from '@/view/layout/header/admin/HeaderMobile.vue'
import HtmlClass from '@/core/services/htmlclass.service'
import KTScrollTop from '@/view/layout/extras/ScrollTop'
import Loader from '@/view/content/Loader.vue'
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from '@/core/services/store/htmlclass.module.js'
import ErrorBoundary from '../../components/error-boundry/index'
// import KTStickyToolbar from "@/view/layout/extras/admin/StickyToolbar.vue";

export default {
  name: 'Layout',
  components: {
    ErrorBoundary,
    KTAside,
    KTHeader,
    KTHeaderMobile,
    KTScrollTop,
    // KTStickyToolbar,
    Loader,
  },
  computed: {
    ...mapGetters([
      'layoutConfig',
    ]),

    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'))
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig('content.width') === 'fluid'
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig('loader.logo')
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig('aside.self.display')
    },

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay() {
      // return !!this.layoutConfig('toolbar.display');
      return true
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig('subheader.display')
    },
  },
  watch: {
    $route: {
      handler(router) {
        if (router.meta.view !== 'admin') {
          if (router.path === '/') this.$router.replace({ name: 'dashboardAdmin' })
          else this.$router.push({ name: '404' })
        }
      },
      deep: true,
      immediate: true,
    },
  },
  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, 'page-loading')

    // initialize html element classes
    HtmlClass.init(this.layoutConfig())
  },
  mounted() {
    // Simulate the delay page loading
    setTimeout(() => {
      // Remove page loader after some time
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, 'page-loading')
    }, 1000)
  },
}
</script>
